import { createContext, useContext, useEffect, useState } from "react";
import { useServer } from "./ServerContext";
import { backendEnv, endpoints } from "../env";
import { useAuth } from "./AuthContext";
import Pusher from "pusher-js";

const ChatContext = createContext(undefined);

export const ChatProvider = ({children}) => {
    //HOOKS
    const { getRequest } = useServer();
    const { user } = useAuth();

    //STATES
    const [messages, setMessages] = useState([]);
    const [deletedMessages, setDeletedMessages] = useState({});
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [respondingToMessage, setRespondingToMessage] = useState(null);
    const [autoScrolling, setAutoScrolling] = useState(true);

    //FUNCTIONS
    const loadChatHistory = () => {
        getRequest(endpoints.get_chat, {}, (res) => {
            if (res[0])
            {
                res = res[1];
                let messagesToAttach = [];
                for (let i = 0; i < res.messages.length; i++)
                {
                    let createdAtLocal = new Date(res.messages[i].created_at).toLocaleTimeString('en-US', {
                        month: 'short',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true
                    });

                    let message = {
                        created_at: createdAtLocal,
                        id: res.messages[i].id,
                        message: res.messages[i].message,
                        deleted: res.messages[i].deleted,
                        author: {
                            username: res.messages[i].username,
                            id: res.messages[i].platform_user_id,
                        },
                    }

                    if (res.messages[i].responding_to !== null)
                    {
                        message.responding_to = {
                            created_at: createdAtLocal,
                            id: res.messages[i].responding_to,
                            username: res.messages[i].responding_to_username,
                            message: res.messages[i].responding_to_message,
                        };
                    }
                    messagesToAttach.push(message);
                }
                setMessages(messagesToAttach);

                setTimeout(() => {
                    if (document.getElementById("bottom_chat_dummy"))
                        document.getElementById("bottom_chat_dummy").scrollIntoView();
                }, 200);
            }
        });
    }

    const getMessageById = (id) => {
        return messages.find((message) => message.id === id) || null;
    }
    
    //EFFECTS
    useEffect(() => {
        var pusher = new Pusher('71009896fab710c57bbd', {
            cluster: 'us2'
        });
      
        //pusher.logToConsole = true;
      
        var channel = pusher.subscribe(backendEnv.globalChatChannel);
      
        channel.bind('message', function(data) {
            const now = Date.now();
            let createdAtLocal = new Date(now).toLocaleTimeString('en-US', {
                month: 'short',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
            });
            setMessages((prev) => [...prev, {...data.message, created_at: createdAtLocal}]);
        });

        channel.bind('deleted_message', function(data) {
            setDeletedMessages((prev) => {
                let newDeletedMessages = {...prev};
                newDeletedMessages["message_" + data.message.id] = true;
                return newDeletedMessages;
            });
        });

        return () => {
            pusher.unsubscribe(backendEnv.globalChatChannel);
        }
    }, []);

    useEffect(() => {
        if (user !== null)
        {
            loadChatHistory();
        }
    }, [user]);

    const values = {
        messages,
        selectedMessage,
        setSelectedMessage,
        respondingToMessage,
        setRespondingToMessage,
        getMessageById,
        autoScrolling,
        setAutoScrolling,
        deletedMessages,
    };

    return (
        <ChatContext.Provider
            value={values}
        >
            {children}
        </ChatContext.Provider>
    );
}

export const useChat = () => useContext(ChatContext);