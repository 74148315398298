import { Route, Routes } from "react-router-dom";
import { Index } from "./pages/index/Index";
import { AuthPageWrapper } from "./pages/AuthPage/AuthPage";
import { ProtectedRoutes } from "./components/ProtectedRoutes/ProtectedRoutes";
import { UserAdminActionsProvider } from "./contexts/UserAdminActionsContext";
import { NewsProvider } from "./contexts/NewsContext";

function App() {
  	return (
    	<>
			<Routes>
				<Route path="/" element={<AuthPageWrapper />} />
			</Routes>
			<NewsProvider>
				<UserAdminActionsProvider>
					<ProtectedRoutes>
						<Route path="/app" element={<Index />} />
					</ProtectedRoutes>
				</UserAdminActionsProvider>
			</NewsProvider>
		</>
	);
}

export default App;
