import './screen_container.css';
import { useAppManager } from '../../contexts/AppManagerContext';

export const ScreenContainer = ({ children, screen, id }) => {
    //HOOKS
    const { currentAppScreen } = useAppManager();

    return (
        <div className={currentAppScreen === screen ? 'screen_container active' : 'screen_container'} id={id}>
            {children}
        </div>
    );
}