import './app_navbar.css';
import { ChatIcon } from '../../assets/icons/ChatIcon';
import { EventsIcon } from '../../assets/icons/EventsIcon';
import { HomeIcon } from '../../assets/icons/HomeIcon';
import { NewsIcon } from '../../assets/icons/NewsIcon';
import { APP_SCREENS, useAppManager } from '../../contexts/AppManagerContext';
import { PhoneIcon } from '../../assets/icons/PhoneIcon';
import { AdminIcon } from '../../assets/icons/AdminIcon';
import { useAuth } from '../../contexts/AuthContext';

export const AppNavbar = () => {
    //HOOKS
    const { changeAppScreen, os } = useAppManager();
    const { user } = useAuth();

    return (
        <div className='app_navbar'>
            <button onClick={() => changeAppScreen(APP_SCREENS.MAIN)}>
                <HomeIcon />
                <p>
                    Inicio
                </p>
            </button>
            <button onClick={() => changeAppScreen(APP_SCREENS.CHAT)}>
                <ChatIcon />
                <p>
                    Chat
                </p>
            </button>
            {
                os !== "android" && (
                    <button onClick={() => changeAppScreen(APP_SCREENS.NEWS_LIST)}>
                        <NewsIcon />
                        <p>
                            Noticias
                        </p>
                    </button>
                )
            }
            {/*<button>
                <EventsIcon />
                <p>
                    Eventos
                </p>
            </button>*/}
            {
                user && !user.status.admin_rights && (
                    <button onClick={() => window.ReactNativeWebView.postMessage("call_7032211480")}>
                        <PhoneIcon />
                        <p>
                            Llamar
                        </p>
                    </button>
                )
            }
            {
                user && user.status.admin_rights && (
                    <button onClick={() => changeAppScreen(APP_SCREENS.ADMIN_MAIN)}>
                        <AdminIcon />
                        <p>
                            Admin
                        </p>
                    </button>
                )
            }
        </div>
    );
}