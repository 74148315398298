import './user_menu_screen.css';
import { APP_SCREENS, useAppManager } from '../../contexts/AppManagerContext';
import { ScreenContainer } from '../ScreenContainer/ScreenContainer';
import { useAuth } from '../../contexts/AuthContext';
import { LogoutIcon } from '../../assets/icons/LogoutIcon';
import { BarLoader } from '../BarLoader/BarLoader';
import { useState } from 'react';
import { ProfilePicture } from '../ProfilePicture/ProfilePicture';
import { AccountDeleteModal } from '../AccountDeleteModal/AccountDeleteModal';
import { AdminDeleteModal } from '../AdminDeleteModal/AdminDeleteModal';

export const UserMenuScreen = () => {
    //HOOKS
    const { user, logout } = useAuth();
    const { changeAppScreen } = useAppManager(); 

    //STATES
    const [loading, setLoading] = useState(false);
    const [deleteAccountModal, setDeleteAccountModal] = useState(false);

    //FUNCTIONS
    const handleLogout = () => {
        setLoading(true);

        logout(() => {
            setLoading(false);
            changeAppScreen(APP_SCREENS.MAIN);
        });
    }

    return (
        <>
            <ScreenContainer screen={APP_SCREENS.USER_MENU} id={"user_menu_screen"}>
                <div className='profile_container'>
                    <div className='profile_pic'>
                        <ProfilePicture />
                    </div>
                    <h2 className='username'>
                        @{
                            user && user.username
                        }
                    </h2>
                    <button className='logout' onClick={handleLogout} disabled={loading}>
                        <LogoutIcon />
                    </button>

                    {
                        loading && (
                            <div className='loader'>
                                <BarLoader />
                            </div>
                        )
                    }
                </div>

                <div className='profile_options'>
                    <button disabled={loading} onClick={() => changeAppScreen(APP_SCREENS.UPDATE_ACCOUNT)}>
                        Actualizar información
                    </button>
                    <button disabled={loading} onClick={() => changeAppScreen(APP_SCREENS.UPDATE_PICTURE)}>
                        Cambiar foto de perfil
                    </button>
                    <button disabled={loading} onClick={() => changeAppScreen(APP_SCREENS.UPDATE_PASSWORD)}>
                        Cambiar contraseña
                    </button>
                    <button disabled={loading} onClick={() => setDeleteAccountModal(true)}>
                        Eliminar cuenta
                    </button>
                    <button onClick={handleLogout} disabled={loading}>
                        Cerrar sesión
                        {
                            loading && (
                                <div className='loader'>
                                    <BarLoader />
                                </div>
                            )
                        }
                    </button>
                </div>
            </ScreenContainer>
            <AccountDeleteModal active={deleteAccountModal} setActive={setDeleteAccountModal} />
        </>
    );
}