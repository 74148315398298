import { DeleteMessageModal } from "../components/DeleteMessageModal/DeleteMessageModal";
import { UserAdminActionsModal } from "../components/UserAdminActionsModal/UserAdminActionsModal";
import { endpoints } from "../env";
import { useServer } from "./ServerContext";

const { createContext, useContext, useState, useEffect } = require("react");

const UserAdminActionsContext = createContext(undefined);

export const UserAdminActionsProvider = ({ children }) => {
    //HOOKS
    const { getRequest } = useServer();

    //STATES
    const [messageToDelete, setMessageToDelete] = useState(null);
    const [userToAdministrate, setUserToAdministrate] = useState(null);
    const [loadingUser, setLoadingUser] = useState(false);
    const [currentSelectedUser, setCurrentSelectedUser] = useState(null);

    //EFFECTS
    useEffect(() => {
        if (userToAdministrate !== null)
        {
            if (loadingUser)
            {
                return;
            }

            setLoadingUser(true);
            getRequest(endpoints.get_user, {user_id: userToAdministrate}, (res) => {
                if (res[0])
                {
                    setCurrentSelectedUser(res[1].user);
                }
                else
                {
                    setCurrentSelectedUser(null);
                }
                setLoadingUser(false);
            });
        }
        else
        {
            setCurrentSelectedUser(null);
        }
    }, [userToAdministrate]);

    const values = {
        messageToDelete,
        setMessageToDelete,
        userToAdministrate,
        setUserToAdministrate,
        currentSelectedUser,
        loadingUser,
    }

    return (
        <UserAdminActionsContext.Provider value={values}>
            <UserAdminActionsModal />
            <DeleteMessageModal />
            {children}
        </UserAdminActionsContext.Provider>
    );
};

export const useUserAdminActions = () => useContext(UserAdminActionsContext);