import './admin_user_item.css';
import { UserProfilePicture } from '../UserProfilePicture/UserProfilePicture';
import { useUserAdminActions } from '../../contexts/UserAdminActionsContext';

export const AdminUserItem = ({user}) => {
    //HOOKS
    const { setUserToAdministrate } = useUserAdminActions();

    return (
        <div className="admin_user_item">
            <div className='main_info'>
                <div className='pic'>
                    <UserProfilePicture userId={user.id} />
                </div>
                <div className='info'>
                    <h2>
                        @{
                            user.username
                        }
                    </h2>
                    <span>
                        {
                            user.status.chat_banned ? "bloqueado" : ""
                        }
                    </span>
                </div>
            </div>
            <div className='actions'>
                <button className='light_btn' onClick={() => setUserToAdministrate(user.id)}>
                    Ver
                </button>
            </div>
        </div>
    );
}