import './chat_message.css';
import DefualtProfileImage from '../../assets/img/default_user_profile.png';
import useLongPress from '../../common/useLongPress';
import { useChat } from '../../contexts/ChatContext';
import { useRef } from 'react';
import { UserProfilePicture } from '../UserProfilePicture/UserProfilePicture';

export const ChatMessage = ({message, own}) => {
    //HOOKS
    const { selectedMessage, setSelectedMessage, setAutoScrolling, deletedMessages } = useChat();

    //REFS
    const messageRef = useRef();
    const responseRef = useRef();
    
    //FUNCTIONS
    const scrollToresponse = () => {
        const responseMessage = document.getElementById('global_chat_message_' + message.responding_to.id);
        if (responseMessage)
        {
            setAutoScrolling(false);
            responseMessage.scrollIntoView({behavior: "smooth", block: 'center', inline: 'center'});
            setTimeout(() => {
                document.getElementById('message_hightlight_' + message.responding_to.id).classList.add('active');
                setTimeout(() => {
                    document.getElementById('message_hightlight_' + message.responding_to.id).classList.remove('active');
                }, 300);
            }, 500);
        }
    }

    //EVENT HANDLERS
    const onLongPress = () => {
        if (own) return;
        setSelectedMessage(message.id);
    };

    const onClick = (event) => {
        if (message.responding_to != null && (!event.target.contains(responseRef.current)) && selectedMessage !== message.id)
        {
            scrollToresponse();
        }

        if (selectedMessage !== message.id)
        {
            setSelectedMessage(null);
        }
    }

    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 600,
    };

    const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

    return (
        <div className={own ? "chat_message own" : "chat_message"} {...longPressEvent} ref={messageRef} id={'global_chat_message_' + message.id}>
            <div className='profile_container'>
                <div className='profile_picture'>
                    <UserProfilePicture userId={message.author.id} />
                </div>
            </div>
            <div className='messages_container'>
                <div className='message'>
                    <span>
                        {message.author.username}
                    </span>
                    {
                        message.responding_to != null && (
                            <div className='responding_to' onClick={scrollToresponse} ref={responseRef}>
                                <span>
                                    {message.responding_to.username}
                                </span>
                                <p>
                                    {
                                        deletedMessages["message_" + message.responding_to.id] ? "Mensaje eliminado" : message.responding_to.message
                                    }
                                </p>
                            </div>
                        )
                    }
                    <p>
                        { (message.deleted === 1 || deletedMessages["message_" + message.id]) ? "Mensaje eliminado" : message.message}
                    </p>
                    <span className='date'>
                        {message.created_at}
                    </span>
                </div>
            </div>

            {
                selectedMessage === message.id && (
                    <div className='selected'></div>
                )
            }

            <div className='highlight' id={'message_hightlight_' + message.id}></div>
        </div>
    );
}