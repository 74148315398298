import { useNews } from '../../contexts/NewsContext';
import './news_item.css';

export const NewsItem = ({news}) => {
    //HOOKS
    const { openNews } = useNews();

    return (
        <div className="news_item" onClick={() => openNews(news)}>
            <img src={news.image} alt="news_image" />
            <div className="content">
                <h2 dangerouslySetInnerHTML={{__html: news.title}}>
                    
                </h2>
            </div>
        </div>
    );
}