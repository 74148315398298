import React from "react";
import { Routes } from "react-router-dom";
import { ProtectedRoute } from "../ProtectedRoute/ProtectedRoute";

export const ProtectedRoutes = (props) => {
    return (
        <>
            <Routes>
                {props.children}
            </Routes>

            {React.Children.map(props.children, child => {
                const { path } = child.props;

                return <ProtectedRoute path={path} />
            })}
        </>
    );

}