import './selected_message_menu.css';
import { useChat } from '../../contexts/ChatContext';
import { BackArrowIcon } from '../../assets/icons/BackArrowIcon';
import { AnswerArrowIcon } from '../../assets/icons/AnswerArrowIcon';
import { useAuth } from '../../contexts/AuthContext';
import { DeleteIcon } from '../../assets/icons/DeleteIcon';
import { useUserAdminActions } from '../../contexts/UserAdminActionsContext';

export const SelectedMessageMenu = () => {
    //HOOKS
    const { selectedMessage, setSelectedMessage, setRespondingToMessage, getMessageById } = useChat();
    const { user } = useAuth();
    const { setMessageToDelete, setUserToAdministrate } = useUserAdminActions();

    //FUNCTIONS
    const respondToMessage = () => {
        const message = getMessageById(selectedMessage);
        setRespondingToMessage(message);
        setSelectedMessage(null);
    }

    const deleteMessate = () => {
        const message = getMessageById(selectedMessage);
        setMessageToDelete(message);
        setSelectedMessage(null);
    }

    const administrativeActions = () => {
        const message = getMessageById(selectedMessage);
        setUserToAdministrate(message.author.id);
    }
    
    return (
        <div className={selectedMessage !== null ? "selected_message_menu active" : "selected_message_menu"}>
            <div>
                <button className='unselect' onClick={() => setSelectedMessage(null)}>
                    <BackArrowIcon />
                </button>
            </div>
            <div className='button_group'>
                {
                    user && user.status.admin_rights && (
                        <>
                            <button className='admin_actions' onClick={administrativeActions}>
                                Acciones administrativas
                            </button>
                            <button className='delete' onClick={deleteMessate}>
                                <DeleteIcon />
                            </button>
                        </>
                    )
                }
                <button className='answer' onClick={respondToMessage}>
                    <AnswerArrowIcon />
                </button>
            </div>
        </div>
    );
}