import { forwardRef, useImperativeHandle, useState } from "react";
import { backendEnv } from "../../env";

export const UserProfilePicture = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
            reload
        }
    });

    //STATES
    const [imageUrl, setImageUrl] = useState(backendEnv.serverUrl + "/profile_pictures/" + props.userId + ".png");

    //FUNCTIONS
    const reload = () => {
        setImageUrl(backendEnv.serverUrl + "/profile_pictures/" + props.userId + ".png?" + new Date().getTime());
    }

    return (
        <img src={imageUrl} {...props} onError={() => setImageUrl(backendEnv.serverUrl + "/profile_pictures/default_user_profile.png")} alt=""/>
    );
});