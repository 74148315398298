import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { appleIdEndpoints, backendEnv, endpoints } from "../env";
import { useCookie } from "./CookieContext";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext(undefined);

export const AuthProvider = ({children}) => {
    //HOOKS
    const api = axios.create({
        baseURL: backendEnv.serverUrl,
        withCredentials: true,
    });

    const { getCookie, setCookie, deleteCookie } = useCookie();

    const navigate = useNavigate();

    //STATES
    const [user, setUser] = useState(null);
    const [isAuth, setIsAuth] = useState(null);

    //FUNCTIONS
    const getAuthToken = () => {
        return getCookie("auth_token");
    }

    const setAuthToken = (token) => {
        setCookie("auth_token", token);
    }

    const deleteAuthToken = () => {
        deleteCookie("auth_token");
    }

    const checkAuth = (callback = null) => {
        const token = getAuthToken();

        api.post(endpoints.auth_check, {}, { headers: { "auth_token" : token } }).then((res) => {
            res = res.data;

            if (res.success) {
                setIsAuth(true);
                setUser(res.user);
            } else {
                setIsAuth(false);
            }

            if (callback) {
                callback(res.success);
            }
        }).catch((res) => {
        });
    }

    const login = (email, password, callback) => {
        const data = {
            email,
            password,
        }

        api.post(endpoints.login, data).then((res) => {
            res = res.data;

            if (res.success) {
                setAuthToken(res.session.token);
                checkAuth(() => {
                    callback([true, res]);
                });
            }
            else
            {
                callback([true, res]);
            }
        }).catch((res) => {
            callback([false, null]);
        });
    }

    const guestLogin = (callback) => {
        api.post(endpoints.guest_login, {}).then((res) => {
            res = res.data;

            if (res.success) {
                setAuthToken(res.session.token);
                checkAuth();
            }

            callback([true, res]);
        }).catch((res) => {
            callback([false, null]);
        });
    }

    const appleLogin = (appleJson) => {
        const data = {
            apple_token: appleJson.identityToken,
            auth_code: appleJson.authorizationCode,
        }

        api.post(appleIdEndpoints.signin_with_apple, data).then((res) => {
            res = res.data;

            if (res.success) {
                setAuthToken(res.session.token);
                checkAuth();
            }
        }).catch((res) => {
        });
    }

    const logout = (callback) => {
        api.post(endpoints.logout, {}).then((res) => {
            deleteAuthToken();
            callback(true);
            checkAuth();
        });
    }

    //EFFECTS
    useEffect(() => {
        checkAuth();    
    }, []);

    const values = {
        user,
        isAuth,
        checkAuth,
        getAuthToken,
        login,
        setAuthToken,
        guestLogin,
        logout,
        appleLogin,
        deleteAuthToken,
    }

    return (
        <AuthContext.Provider
            value={values}
        >
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => useContext(AuthContext);