import { AdminMainScreen } from "../../components/AdminMainScreen/AdminMainScreen";
import { AppHeader } from "../../components/AppHeader/AppHeader";
import { AppNavbar } from "../../components/AppNavbar/AppNavbar";
import { ChatScreen } from "../../components/ChatScreen/ChatScreen";
import { MainScreen } from "../../components/MainScreen/MainScreen";
import { NewsListScreen } from "../../components/NewsListScreen/NewsListScreen";
import { NewsPostScreen } from "../../components/NewsPostScreen/NewsPostScreen";
import { RadioPlayer } from "../../components/RadioPlayer/RadioPlayer";
import { UpdateAccountScreen } from "../../components/UpdateAccountScreen/UpdateAccountScreen";
import { UpdatePasswordScreen } from "../../components/UpdatePasswordScreen/UpdatePasswordScreen";
import { UserChangePictureScreen } from "../../components/UserChangePictureScreen/UserChangePictureScreen";
import { UserMenuScreen } from "../../components/UserMenuScreen/UserMenuScreen";

export const Index = () => {
    return (
        <>
            <AppHeader />
            <>
                {/* Content */}
                <MainScreen />
                <ChatScreen />
                <NewsPostScreen />
                <NewsListScreen />
                <UserMenuScreen />
                <UpdateAccountScreen />
                <UpdatePasswordScreen />
                <UserChangePictureScreen />
                <AdminMainScreen />
            </>
            <RadioPlayer />
            <AppNavbar />
        </>
    );
}