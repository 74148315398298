import './app_header.css';
import LogoContrastImage from '../../assets/img/logo_contraste.png';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { APP_SCREENS, useAppManager } from '../../contexts/AppManagerContext';
import { ProfilePicture } from '../ProfilePicture/ProfilePicture';

export const AppHeader = () => {
    //HOOKS
    const { user } = useAuth();
    const { changeAppScreen } = useAppManager();

    return (
        <header className='app_header'>
            <Link to={'/app'} onClick={() => changeAppScreen(APP_SCREENS.MAIN)}>
                <img className='logo' src={LogoContrastImage} alt='Logo' />
            </Link>
            <div className='profile' onClick={() => changeAppScreen(APP_SCREENS.USER_MENU)}>
                <div className='profile_picture'>
                    <ProfilePicture />
                </div>
                <p>
                    { user && user.username}
                </p>
            </div>
        </header>
    );
}