import { createContext, useContext, useEffect, useState } from "react";
import { useServer } from "../../contexts/ServerContext";
import { userAdminEndpoints } from "../../env";
import { APP_SCREENS, useAppManager } from "../../contexts/AppManagerContext";

const ManageBannedContext = createContext(undefined);

export const ManageBannedProvider = ({children}) => {
    //HOOKS
    const { getRequest } = useServer();
    const { currentAppScreen } = useAppManager();

    //STATES
    const [users, setUsers] = useState([]);
    const [loader, setLoader] = useState(false);
    const [userToUnban, setUserToUnban] = useState(null);

    //FUNCTIONS
    const loadBannedUsers = () => {
        if (loader)
        {
            return;
        }

        setLoader(true);

        getRequest(userAdminEndpoints.users_list_banned, {}, (res) => {
            setLoader(false);
            if (res[0])
            {
                setUsers(res[1].users);
            }
        });
    }

    //EFFECTS
    useEffect(() => {
        if (currentAppScreen === APP_SCREENS.MANAGE_BANNED)
        {
            loadBannedUsers();
        }
    }, [currentAppScreen]);

    const value = {
        users,
        loader,
        loadBannedUsers,
        userToUnban,
        setUserToUnban,
    }

    return (
        <ManageBannedContext.Provider value={value}>
            {children}
        </ManageBannedContext.Provider>
    )
}

export const useManageBanned = () => useContext(ManageBannedContext);