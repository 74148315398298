import './login_modal.css';
import { AuthModal } from "../AuthModal/AuthModal";
import { BarLoader } from '../BarLoader/BarLoader';
import { useState } from 'react';
import { AUTH_MODALS } from '../../pages/AuthPage/AuthPageContext';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

export const LoginModal = () => {
    //HOOKS
    const { login } = useAuth();
    const navigate = useNavigate();

    //STATES
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");

    //FUNCTIONS
    const postLogin = () => {
        setLoading(true);
        setErrorMsg("");
        setEmailError("");
        setPasswordError("");

        login(email, password, (res) => {
            setLoading(false);
            if (res[0])
            {
                res = res[1];

                if (res.success)
                {
                    navigate("/app", {replace: true});
                }
                else
                {
                    if (res.error_code === "validator_exception")
                    {
                        if (res.errors.email)
                        {
                            setEmailError("Se requiere el correo");
                        }

                        if (res.errors.password)
                        {
                            setPasswordError("Se requiere la contraseña");
                        }
                    }

                    if (res.error_code === "wrong_credentials")
                    {
                        setErrorMsg("Correo o contraseña incorrectos");
                    }
                }
            }
            else
            {
                setErrorMsg("Error de comunicación con el servidor, por favor intenta mas tarde.");
            }
        });
    }

    return (
        <AuthModal title="Iniciar sesión" modal={AUTH_MODALS.LOGIN_MODAL}>
            <div className='input_container'>
                <label>
                    Correo
                </label>
                <input type='text' placeholder='email@email.com' value={email} onChange={(e) => setEmail(e.target.value)} />
                <span>
                    {emailError}
                </span>
            </div>
            <div className='input_container'>
                <label>
                    Contraseña
                </label>
                <input type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                <span>
                    {passwordError}    
                </span> 
            </div>
            <div className='login_action'>
                <button className='light_btn' disabled={loading} onClick={postLogin}>
                    Iniciar sesión
                </button>

                <span className='error'>
                    {errorMsg}
                </span>

                {
                    loading && (
                        <div className='loader'>
                            <BarLoader />
                        </div>
                    )
                }
            </div>
        </AuthModal>
    );
}