import './news_list_screen.css';
import { BackArrowIcon } from "../../assets/icons/BackArrowIcon";
import { APP_SCREENS, useAppManager } from "../../contexts/AppManagerContext";
import { ScreenContainer } from "../ScreenContainer/ScreenContainer";
import { useNews } from '../../contexts/NewsContext';
import { NewsItem } from '../NewsItem/NewsItem';
import { NewsPaginate } from '../NewsPaginate/NewsPaginate';
import { BarLoader } from '../BarLoader/BarLoader';

export const NewsListScreen = () => {
    //HOOKS
    const { GoToPrevius } = useAppManager();
    const { news, newsLoading } = useNews();

    return (
        <ScreenContainer screen={APP_SCREENS.NEWS_LIST} id={"news_list_screen"}>
            <div className='header' id='news_list_header'>
                <button onClick={GoToPrevius}>
                    <BackArrowIcon />
                </button>
                <h2>
                    Noticias
                </h2>
                {
                    newsLoading && (
                        <div className='loader'>
                            <BarLoader />
                        </div>
                    )
                } 
            </div>
            <div className='body'>
                {
                    news.map((newsItem) => {
                        return <NewsItem key={"news_item_" + newsItem.id} news={newsItem} />
                    })
                }
            </div>
            <div className='paginate'>
                <NewsPaginate />
            </div>
        </ScreenContainer>
    );
}