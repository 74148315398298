import './user_change_picture_screen.css';
import { APP_SCREENS, useAppManager } from "../../contexts/AppManagerContext";
import { ProfilePicture } from "../ProfilePicture/ProfilePicture";
import { ScreenContainer } from "../ScreenContainer/ScreenContainer";
import { useRef, useState } from 'react';
import { BarLoader } from '../BarLoader/BarLoader';
import { useServer } from '../../contexts/ServerContext';
import { endpoints } from '../../env';
import { useAuth } from '../../contexts/AuthContext';

export const UserChangePictureScreen = () => {
    //HOOKS
    const { GoToPrevius } = useAppManager();
    const { postRequest } = useServer();
    const { checkAuth } = useAuth();

    //STATES
    const [loading, setLoading] = useState(false);
    const [imageError, setImageError] = useState("");
    const [previewImage, setPreviewImage] = useState("");

    //REFS
    const fileRef = useRef();

    //FUNCTIONS
    const postUploadPicture = () => {
        if (loading)
        {
            return;
        }

        if (!checkFormat())
        {
            setImageError("Solo se permiten formatos png y jpg");
            return;
        }

        setLoading(true);
        setImageError("");
        let fd = new FormData();
        fd.append("picture", fileRef.current.files[0]);

        postRequest(endpoints.user_upload_picture, fd, (res) => {
            setLoading(false);
            fileRef.current.value = "";
            setPreviewImage("");
            checkAuth();
            GoToPrevius();
        });
    }

    const deleteProfilePicture = () => {
        if (loading)
        {
            return;
        }

        setLoading(true);

        postRequest(endpoints.user_delete_picture, {}, (res) => {
            setLoading(false);
            fileRef.current.value = "";
            setPreviewImage("");
            checkAuth();
            GoToPrevius();
        });
    }

    const checkFormat = () => {
        return (fileRef.current.files[0].type === "image/png" || fileRef.current.files[0].type === "image/jpeg" || fileRef.current.files[0].type === "image/jpg");
    }

    //EVENT HANDLERS
    const imageLoaded = (e) => {
        if (!checkFormat())
        {
            setImageError("Solo se permiten formatos png y jpg");
            setPreviewImage("");
            return;
        }
        setImageError("");
        setPreviewImage(URL.createObjectURL(e.target.files[0]));
    }

    return (
        <ScreenContainer screen={APP_SCREENS.UPDATE_PICTURE} id={'update_picture_screen'}>
            <div className='profile_picture_form'>
                <div className='picture'>
                    <ProfilePicture />
                </div>
                <div>
                    <button className='light_btn' disabled={loading} onClick={deleteProfilePicture}>
                        Eliminar foto de perfil
                    </button>
                </div>
            </div>
            <div className="form_container">
                <div className='input_container'>
                    <label>
                        Foto de perfil
                    </label>
                    <input type='file' ref={fileRef} onChange={imageLoaded}/>
                    <span>
                        {imageError}
                    </span>
                </div>
                <img src={previewImage} alt='' />
                <div className='action'>
                    <button className='light_btn' onClick={GoToPrevius} disabled={loading}>
                        Cancelar
                    </button>
                    <button className='light_btn' disabled={loading} onClick={postUploadPicture}>
                        Guardar
                    </button>
                </div>
                {
                    loading && (
                        <div className='loader'>
                            <BarLoader />
                        </div>
                    )
                }
            </div>
        </ScreenContainer>
    );
}