import { createContext, useContext, useEffect, useState } from "react";
import { useServer } from "./ServerContext";
import { endpoints } from "../env";
import { APP_SCREENS, useAppManager } from "./AppManagerContext";

const NewsContext = createContext(undefined);

export const NewsProvider = ({ children }) => {
    //HOOKS
    const { getRequest } = useServer();
    const { changeAppScreen } = useAppManager();

    //STATES
    const [postsPerPage, setPostsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [lastNews, setLastNews] = useState([]);
    const [news, setNews] = useState([]);
    const [newsLoading, setNewsLoading] = useState(false);
    const [currentNews, setCurrentNews] = useState(null);

    //FUNCTIONS
    const getLastNews = () => {
        getRequest(endpoints.get_last_news, {}, (res) => {
            if (res[0])
            {
                setLastNews(res[1].posts);
            }
        });
    }

    const openNews = (news) => {
        setCurrentNews(news);
        document.getElementById('news_post_view_header').scrollIntoView();
        changeAppScreen(APP_SCREENS.NEWS_VIEW);
    }

    const loadNews = () => {
        if (newsLoading)
            return;

        setNews([]);
        setNewsLoading(true);

        const data = {
            per_page: postsPerPage,
            page: currentPage,
        }

        getRequest(endpoints.get_news, data, (res) => {
            setNewsLoading(false);
            if (res[0])
            {
                setNews(res[1].posts.data);
                setTotalPages(parseInt(Math.ceil(res[1].posts.total / postsPerPage)));
            }
        });
    }

    //EFFECTS
    useEffect(() => {
        getLastNews();
    }, []);

    useEffect(() => {
        loadNews();
    }, [currentPage]);

    const values = {
        lastNews,
        currentNews,
        openNews,
        news,
        postsPerPage,
        currentPage,
        setCurrentPage,
        totalPages,
        newsLoading,
    };

    return (
        <NewsContext.Provider value={values}>
            {children}
        </NewsContext.Provider>
    )
};

export const useNews = () => useContext(NewsContext);