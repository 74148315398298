import './admin_main_screen.css';
import { useState } from "react";
import { LogoutIcon } from "../../assets/icons/LogoutIcon";
import { APP_SCREENS, useAppManager } from "../../contexts/AppManagerContext";
import { useAuth } from "../../contexts/AuthContext";
import { BarLoader } from "../BarLoader/BarLoader";
import { ProfilePicture } from "../ProfilePicture/ProfilePicture";
import { ScreenContainer } from "../ScreenContainer/ScreenContainer";
import { AdminBannersScreen } from '../AdminBannersScreen/AdminBannersScreen';
import { AdminUsersScreen } from '../AdminUsersScreen/AdminUsersScreen';
import { ManageAdminsScreenWrapper } from '../ManageAdminsScreen/ManageAdminsScreen';
import { ManageBannedScreenWrapper } from '../ManageBannedScreen/ManageBannedScreen';

export const AdminMainScreen = () => {
    //HOOKS
    const { user, logout } = useAuth();
    const { changeAppScreen } = useAppManager();

    //STATES
    const [loading, setLoading] = useState(false);

    //FUNCTIONS
    const handleLogout = () => {
        setLoading(true);

        logout(() => {
            setLoading(false);
            changeAppScreen(APP_SCREENS.MAIN);
        });
    }

    return (
        <>
            <ScreenContainer screen={APP_SCREENS.ADMIN_MAIN} id={"admin_main_screen"}>
                <div className='profile_container'>
                    <div className='profile_pic'>
                        <ProfilePicture />
                    </div>
                    <h2 className='username'>
                        @{
                            user && user.username
                        }
                    </h2>
                    <button className='logout' onClick={handleLogout} disabled={loading}>
                        <LogoutIcon />
                    </button>

                    {
                        loading && (
                            <div className='loader'>
                                <BarLoader />
                            </div>
                        )
                    }
                </div>

                <div className='admin_options'>
                    <button disabled={loading} onClick={() => changeAppScreen(APP_SCREENS.ADMIN_BANNERS)}>
                        Banners
                    </button>
                    {/*<button disabled={loading} onClick={() => changeAppScreen(APP_SCREENS)}>
                        Notificaciones Push
                    </button>*/}
                    <button disabled={loading} onClick={() => changeAppScreen(APP_SCREENS.MANAGE_ADMINS)}>
                        Administradores
                    </button>
                    <button disabled={loading} onClick={() => changeAppScreen(APP_SCREENS.ADMIN_USERS)}>
                        Usuarios
                    </button>
                    <button disabled={loading} onClick={() => changeAppScreen(APP_SCREENS.MANAGE_BANNED)}>
                        Usuarios bloqueados
                    </button>
                    <button onClick={handleLogout} disabled={loading}>
                        Cerrar sesión
                        {
                            loading && (
                                <div className='loader'>
                                    <BarLoader />
                                </div>
                            )
                        }
                    </button>
                </div>
            </ScreenContainer>

            <AdminBannersScreen />
            <AdminUsersScreen /> 
            <ManageAdminsScreenWrapper />
            <ManageBannedScreenWrapper />
        </>
    );
}