import './auth_page.css';
import Logo from '../../assets/img/logo_contraste.png';
import Shape1 from '../../assets/img/prototype/test_shape.png';
import Shape2 from '../../assets/img/prototype/test_shape2.png';
import { BarLoader } from '../../components/BarLoader/BarLoader';
import { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { LoginModal } from '../../components/LoginModal/LoginModal';
import { AUTH_MODALS, AuthPageProvider, useAuthPage } from './AuthPageContext';
import { RegisterModal } from '../../components/RegisterModal/RegisterModal';
import { AppleLogo } from '../../assets/icons/AppleLogo';
import { useAppManager } from '../../contexts/AppManagerContext';

export const AuthPageWrapper = () => {
    return (
        <AuthPageProvider>
            <AuthPage />
        </AuthPageProvider>
    );
}

export const AuthPage = () => {
    //HOOKS
    const { isAuth, guestLogin, appleLogin } = useAuth();
    const navigate = useNavigate();
    const { activeModal, setActiveModal } = useAuthPage();
    const { os } = useAppManager();

    //STATES
    const [authLoading, setAuthLoading] = useState(true);
    const [guestLoading, setGuestLoading] = useState(false);

    //FUNCTIONS
    const loginAsGuest = () => {
        setGuestLoading(true);

        guestLogin((res) => {
            navigate('/app', {replace: true});
        });
    }

    useEffect(() => {
        if (isAuth !== null)
        {
            if (isAuth)
            {
                setTimeout(() => {
                    navigate('/app', {replace: true});
                }, 2000);
            }
            else
            {
                setTimeout(() => {
                    setAuthLoading(false);
                }, 2000);
            }
        }
    }, [isAuth]);

    useEffect(() => {
        
        const messageListener = (nativeEvent) => {
            const data = nativeEvent?.data;
            
            if (typeof data === "string" && !data.includes("webpackHotUpdate")) {
                console.log(data);
                const object = JSON.parse(data);
                if (object.source === "react_native") {
                    if (object.message === "apple_auth")
                    {
                        appleLogin(object);
                    }
                }
            }
        };
    
        window.addEventListener('message', messageListener);
    
        return () => {
            window.removeEventListener('message', messageListener);
        };
    }, []);

    return (
        <>
            <div className={authLoading ? 'auth_page loading' : 'auth_page'}>
                <img src={Shape1} className='auth_shape1' alt='' />
                <img src={Shape2} className='auth_shape2' alt='' />

                <div className="auth_logo">
                    <img src={Logo} alt="" />
                    <div className='loader'>
                        <BarLoader />
                    </div>
                </div>

                <div className='login_options'>
                    <button onClick={() => setActiveModal(AUTH_MODALS.LOGIN_MODAL)} disabled={guestLoading}>
                        Iniciar sesion
                    </button>
                    {
                        os === "ios" && (
                            <button className='apple_button' onClick={() => window.ReactNativeWebView.postMessage("apple_login")} disabled={guestLoading}>
                                <AppleLogo /> <span>Inicie sesión con Apple</span>
                            </button>
                        )
                    }
                    
                    <button disabled={guestLoading} onClick={() => setActiveModal(AUTH_MODALS.REGISTER_MODAL)}>
                        Registrate
                    </button>
                    <button disabled={guestLoading} onClick={loginAsGuest}>
                        Ingresar como invitado

                        {
                            guestLoading && (
                                <div className='loader'>
                                    <BarLoader />
                                </div>
                            )
                        }
                    </button>
                </div>

                <div className='social_media_container'>
                    <Link to={'https://www.tiktok.com/@brava1480'} target='_blank'>
                        <i className="fa-brands fa-tiktok"></i>
                    </Link>
                    <Link to={"https://www.instagram.com/brava1480am/"} target='_blank'>
                        <i className="fa-brands fa-instagram"></i>
                    </Link>
                    <Link to={"https://web.facebook.com/brava1480?locale=es_LA"} target='_blank'>
                        <i className="fa-brands fa-facebook-f"></i>
                    </Link>
                </div>
            </div>

            <div className={activeModal ? 'modal_blur active' : 'modal_blur'}>

            </div>
            <LoginModal />
            <RegisterModal />
        </>
    );
}