import { useState } from "react";
import { BarLoader } from "../BarLoader/BarLoader";
import { useServer } from "../../contexts/ServerContext";
import { platformUserEndpoints } from "../../env";
import { useAuth } from "../../contexts/AuthContext";

export const AccountDeleteModal = ({active, setActive}) => {
    //HOOKS
    const { postRequest } = useServer();
    const { deleteAuthToken, checkAuth } = useAuth();

    //STATES
    const [loading, setLoading] = useState(false);

    //FUNCTIONS
    const deleteAccount = () => {
        if (loading)
        {
            return;
        }

        setLoading(true);

        postRequest(platformUserEndpoints.delete_account, {}, (res) => {
            setLoading(false);
            setActive(false);
            deleteAuthToken();
            checkAuth();
        });
    }

    return (
        <div className={active ? 'delete_modal active' : 'delete_modal'}>
            <div className='delete_form'>
                <h2>
                    ¿Estas seguro de eliminar tu cuenta?
                </h2>
                <p>
                    Se eliminara toda la información referente a tu cuenta, esta acción no se podra deshacer.
                </p>

                <div className='actions'>
                    <div></div>
                    <div>
                        <button className='cancel' disabled={loading} onClick={() => setActive(false)}>Cancelar</button>
                        <button className='delete' disabled={loading} onClick={deleteAccount}>Eliminar cuenta</button>
                    </div>
                    {
                        loading && (
                            <div className='loader'>
                                <BarLoader />
                            </div>
                        
                        )
                    }
                </div>
            </div>
        </div>
    );
}