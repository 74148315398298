import './news_paginate.css';
import { useNews } from '../../contexts/NewsContext';

export const NewsPaginate = () => {
    //HOOKS
    const {
        currentPage,
        setCurrentPage,
        totalPages,
        newsLoading,
    } = useNews();

    //FUNCTIONS
    const goNext = () => {
        if (currentPage < totalPages)
        {
            setCurrentPage(currentPage + 1);
            document.getElementById('news_list_header').scrollIntoView();
        }
    }

    const goBack = () => {
        if (currentPage > 1)
        {
            setCurrentPage(currentPage - 1);
            document.getElementById('news_list_header').scrollIntoView();
        }
    }

    return (
        <div className="news_paginator">
             <button onClick={goBack} disabled={newsLoading} className={currentPage === 1 ? "hidden" : ""}>
                Anterior
             </button>
             <h2>
                {currentPage} / {totalPages}
             </h2>
             <button onClick={goNext} disabled={newsLoading} className={currentPage === totalPages ? "hidden" : ""}>
                Siguiente
             </button>
        </div>
    )
}