import './chat_input.css';
import { useState } from "react";
import { ImageIcon } from "../../assets/icons/ImageIcon";
import { SendIcon } from "../../assets/icons/SendIcon";
import { useServer } from "../../contexts/ServerContext";
import { endpoints } from "../../env";
import { useChat } from '../../contexts/ChatContext';
import { CloseIcon } from '../../assets/icons/CloseIcon';
import { BarLoader } from '../BarLoader/BarLoader';

export const ChatInput = () => {
    //HOOKS
    const { postRequest } = useServer();
    const { respondingToMessage, setRespondingToMessage } = useChat();

    //STATES
    const [message, setMessage] = useState("");
    const [sending, setSending] = useState(false);

    //FUNCTIONS
    const postMessage = () => {
        if (message === "")
        {
            return;
        }

        setSending(true);

        const data = {
            message,
        }

        if (respondingToMessage !== null)
        {
            data.responding_to = respondingToMessage.id;
        }

        postRequest(endpoints.send_message, data, (res) => {
            setSending(false);
            setMessage("");

            if (respondingToMessage !== null)
            {
                setRespondingToMessage(null);
            }

            document.getElementById('bottom_chat_dummy').scrollIntoView({behavior: "smooth"});
        });
    }
    
    return (
        <div className='chat_input'>
            {/*
                <button className='attachment' disabled={sending}>
                    <ImageIcon />
                </button>
            */}
            <div className='input'>
                {
                    respondingToMessage !== null && (
                        <div className='response'>
                            
                            <h2>{respondingToMessage.author.username}</h2>
                            <p>
                                {respondingToMessage.message}
                            </p>
                            <button className='close' onClick={() => {setRespondingToMessage(null)}}>
                                <CloseIcon />
                            </button>
                        </div>
                    )
                    
                }
                <textarea placeholder='Escribe un mensaje' value={message} onChange={(e) => setMessage(e.target.value)} disabled={sending}></textarea>
            </div>
            <button className='send' onClick={postMessage} disabled={sending}>
                <SendIcon />
            </button>

            {
                sending && (
                    <div className='loader'>
                        <BarLoader />
                    </div>
                )
            }
        </div>
    );
}