import './update_account_screen.css';
import { APP_SCREENS, useAppManager } from "../../contexts/AppManagerContext";
import { ScreenContainer } from "../ScreenContainer/ScreenContainer";
import { useAuth } from '../../contexts/AuthContext';
import { useEffect, useState } from 'react';
import { BarLoader } from '../BarLoader/BarLoader';
import { useServer } from '../../contexts/ServerContext';
import { endpoints } from '../../env';
import { ProfilePicture } from '../ProfilePicture/ProfilePicture';

export const UpdateAccountScreen = () => {
    //HOOKS
    const { user, checkAuth } = useAuth();
    const { GoToPrevius, currentAppScreen, changeAppScreen } = useAppManager();
    const { postRequest } = useServer();

    //STATES
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [username, setUsername] = useState("");
    const [usernameError, setUsernameError] = useState("");
    const [name, setName] = useState("");


    //FUNCTIONS
    const postUpdate = () => {
        if (loading)
        {
            return;
        }

        setLoading(true);
        resetErrors();

        let data = {
            email,
            name,
            username,
        }

        postRequest(endpoints.user_update, data, (res) => {
            setLoading(false);

            if (res[0])
            {
                res = res[1];

                if (res.success)
                {
                    checkAuth();
                    GoToPrevius();
                }
                else
                {
                    if (res.error_code === "email_in_use")
                    {
                        setEmailError("El correo ya esta en uso.")
                    }
                    if (res.error_code === "username_in_use")
                    {
                        setUsernameError("El nombre de usuario ya esta en uso.")
                    }
                }
            }
            else
            {
                //SERVER ERROR
            }
        });
    }

    const resetErrors = () => {
        setEmailError("");
        setUsernameError("");
    }

    //EFFECTS
    useEffect(() => {
        if (currentAppScreen === APP_SCREENS.UPDATE_ACCOUNT && user !== null) {
            setEmail(user.email);
            setUsername(user.username);
            setName(user.name);
            resetErrors();
        }
    }, [currentAppScreen, user]); 

    return (
        <ScreenContainer screen={APP_SCREENS.UPDATE_ACCOUNT} id={"update_account_screen"}>
            <div className='profile_picture_form'>
                <div className='picture'>
                    <ProfilePicture />
                </div>
                <div>
                    <button className='light_btn' disabled={loading} onClick={() => changeAppScreen(APP_SCREENS.UPDATE_PICTURE)}>
                        Cambiar foto de perfil
                    </button>
                </div>
            </div>
            <div className="form_container">
                <div className='input_container'>
                    <label>
                        Correo
                    </label>
                    <input type='email' placeholder='email@domain.com' value={email} onChange={(e) => setEmail(e.target.value)} />
                    <span>
                        {emailError}
                    </span>
                </div>
                <div className='input_container'>
                    <label>
                        Nombre de usuario
                    </label>
                    <input type='text' placeholder='nombre de usuario' value={username} onChange={(e) => setUsername(e.target.value)} />
                    <span>
                        {usernameError}
                    </span>
                </div>
                <div className='input_container'>
                    <label>
                        Nombre
                    </label>
                    <input type='text' placeholder='nombre' value={name} onChange={(e) => setName(e.target.value)} />
                </div>
                <div className='action'>
                    <button className='light_btn' onClick={GoToPrevius} disabled={loading}>
                        Cancelar
                    </button>
                    <button className='light_btn' disabled={loading} onClick={postUpdate}>
                        Guardar
                    </button>
                </div>

                {
                    loading && (
                        <div className='loader'>
                            <BarLoader />
                        </div>
                    )
                }
            </div>
        </ScreenContainer>
    );
}
