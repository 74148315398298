import './admin_user_screen.css';
import { APP_SCREENS, useAppManager } from "../../contexts/AppManagerContext";
import { ScreenContainer } from "../ScreenContainer/ScreenContainer";
import { useEffect, useState } from 'react';
import { BarLoader } from '../BarLoader/BarLoader';
import { BackArrowIcon } from '../../assets/icons/BackArrowIcon';
import { useServer } from '../../contexts/ServerContext';
import { userAdminEndpoints } from '../../env';
import { AdminUserItem } from '../AdminUserItem/AdminUserItem';

export const AdminUsersScreen = () => {
    //HOOKS
    const { getRequest } = useServer();
    const { currentAppScreen } = useAppManager();

    //STATES
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [username, setUsername] = useState("");
    const [usernameError, setUsernameError] = useState("");
    const [loader, setLoader] = useState(false);
    const [searchCollapsed, setSearchCollapsed] = useState(false);

    const [users, setUsers] = useState([]);

    //FUNCTIONS
    const cancelSearch = () => {
        setSearchCollapsed(false);
        setUsername("");
        setEmail("");
        loadUsers(null, true);
    }

    const loadUsers = (e, forceNoSearh = false) => {
        setLoader(true);
        
        let data = {
            email,
            name: username,
        }

        let endpoint = ((email.trim() !== "" || username.trim() !== "") && forceNoSearh === false) ? userAdminEndpoints.users_search : userAdminEndpoints.users_list;

        getRequest(endpoint, data, (res) => {
            setLoader(false);
            if (res[0])
            {
                res = res[1];
                setUsers(res.users);
            }
        });
    }

    //EFFECTS
    useEffect(() => {
        if (currentAppScreen === APP_SCREENS.ADMIN_USERS)
        {
            loadUsers();
        }
        else
        {
            cancelSearch();
        }
    }, [currentAppScreen]);

    return (
        <ScreenContainer screen={APP_SCREENS.ADMIN_USERS} id="admin_users_screen">
            <div className={searchCollapsed ? 'search_user_container collapsed' : 'search_user_container'}>
                <div className='header'>
                    <h2>
                        Buscar usuarios
                    </h2>
                    <button onClick={() => setSearchCollapsed(!searchCollapsed)}>
                        <BackArrowIcon />
                    </button>
                </div>
                <div className='input_container'>
                    <label>
                        Correo
                    </label>
                    <input type='email' placeholder='email@domain.com' value={email} onChange={(e) => setEmail(e.target.value)} />
                    <span>
                        {emailError}
                    </span>
                </div>
                <div className='input_container'>
                    <label>
                        Nombre de usuario 
                    </label>
                    <input type='email' placeholder='@' value={username} onChange={(e) => setUsername(e.target.value)} />
                    <span>
                        {usernameError}
                    </span>
                </div>
                <div className='actions'>
                    <div>

                    </div>
                    <div className='btns'>
                        <button className='light_btn' disabled={loader} onClick={cancelSearch}>
                            Cancelar
                        </button>
                        <button className='light_btn' disabled={loader} onClick={loadUsers}>
                            Buscar
                        </button>
                    </div>
                </div>

                {
                    loader && (
                        <div className='loader'>
                            <BarLoader />
                        </div>
                    )
                }
            </div>
            
            <div className='users_list'>
                <div className='header'>
                    <h2>
                        Usuarios
                    </h2>
                    <button className='light_btn' onClick={loadUsers}>
                        Recargar
                    </button>
                    {
                        loader && (
                            <div className='loader'>
                                <BarLoader />
                            </div>
                        )
                    }
                </div>

                {
                    users && users.map((user) => {
                        return <AdminUserItem user={user} key={'admin_users_' + user.id} />
                    })
                }
            </div>
        </ScreenContainer>
    );
}