import { createContext, useContext, useState } from "react";

const AuthPageContext = createContext(undefined);

export const AUTH_MODALS = {
    LOGIN_MODAL : 1,
    REGISTER_MODAL: 2,
}

export const AuthPageProvider = ({children}) => {
    //STATES
    const [activeModal, setActiveModal] = useState(null);

    const values = {
        activeModal,
        setActiveModal,
    }

    return (
        <AuthPageContext.Provider
            value={values}
        >
            {children}
        </AuthPageContext.Provider>
    );
}

export const useAuthPage = () => useContext(AuthPageContext);