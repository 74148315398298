import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AppManagerProvider } from './contexts/AppManagerContext';
import { BrowserRouter } from 'react-router-dom';
import { CookieProvider } from './contexts/CookieContext';
import { AuthProvider } from './contexts/AuthContext';
import { ServerProvider } from './contexts/ServerContext';
import { ChatProvider } from './contexts/ChatContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
		<BrowserRouter>
			<CookieProvider>
				<AuthProvider>
					<ServerProvider>
						<ChatProvider>
							<AppManagerProvider>
								<App />
							</AppManagerProvider>
						</ChatProvider>
					</ServerProvider>
				</AuthProvider>
			</CookieProvider>
		</BrowserRouter>
  	</React.StrictMode>
);
