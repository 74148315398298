import { useState } from "react";
import { BarLoader } from "../BarLoader/BarLoader";
import { useManageAdmin } from "../ManageAdminsScreen/ManageAdminsContext";
import { useServer } from "../../contexts/ServerContext";
import { manageAdminsEndpoinst } from "../../env";

export const AdminDeleteModal = () => {
    //HOOKS
    const { adminToDelete, setAdminToDelete, loadAdmins } = useManageAdmin();
    const { postRequest } = useServer();

    //STATES
    const [loading, setLoading] = useState(false);

    //FUNCTIONS
    const deleteAdmin = () => {
        if (loading)
        {
            return;
        }

        setLoading(true);

        const data = {
            id: adminToDelete.id
        }

        postRequest(manageAdminsEndpoinst.remove_admin, data, (res) => {
            setLoading(false);
            loadAdmins();
            setAdminToDelete(null);
        });
    }
    
    return (
        <div className={adminToDelete === null ? 'delete_modal' : 'delete_modal active'}>
            <div className='delete_form'>
                <h2>
                    ¿Estas seguro de borrar el administrador?
                </h2>
                <p>
                    Usuario: {adminToDelete && adminToDelete.username}
                    <br />
                    El usuario ya no tendra privilegios de administración
                </p>

                <div className='actions'>
                    <div></div>
                    <div>
                        <button className='cancel' disabled={loading} onClick={() => setAdminToDelete(null)}>Cancelar</button>
                        <button className='delete' disabled={loading} onClick={deleteAdmin}>Quitar privilegios</button>
                    </div>
                    {
                        loading && (
                            <div className='loader'>
                                <BarLoader />
                            </div>
                        
                        )
                    }
                </div>
            </div>
        </div>
    );
}