import './radio_player.css';
import LogoImage from '../../assets/img/logo.png';
import { useEffect, useRef, useState } from 'react';
import { PauseIcon } from '../../assets/icons/PauseIcon';
import { PlayIcon } from '../../assets/icons/PlayIcon';
import { RadioEnv } from '../../env';
import { BarLoader } from '../BarLoader/BarLoader';

export const RadioPlayer = () => {
    //REFS
    const audioRef = useRef(null);

    //STATES
    const [isPlaying, setIsPlaying] = useState(true);
    const [source, setSource] = useState(RadioEnv.streamUrl);
    const [isLoading, setIsLoading] = useState(false);

    //FUNCTIONS
    const togglePlayer = () => {
        if (isPlaying)
        {
            setIsPlaying(false);
            window.ReactNativeWebView.postMessage("pause");
        }
        else
        {
            setIsPlaying(true);
            window.ReactNativeWebView.postMessage("play");
        }
    }

    useEffect(() => {
        if (audioRef.current)
        {
            audioRef.current.addEventListener('loadeddata', () => {
                setIsLoading(false);
            });
        }

        return (
            () => {
                if (audioRef.current)
                {
                    audioRef.current.removeEventListener('loadeddata', () => {
                        setIsLoading(false);
                    });
                }
            }
        );
    }, [audioRef]);

    useEffect(() => {
        return;
            if (isPlaying)
                {
                    setIsLoading(true);
                    setSource(RadioEnv.streamUrl);
                    audioRef.current.load();
                    audioRef.current.play();
                }
                else
                {
                    setSource("")
                    audioRef.current.setSource = "";
                    audioRef.current.pause();
                }
   
    }, [isPlaying]);

    useEffect(() => {
        window.addEventListener("click", () => {
            setIsPlaying(true);
          }, { once: true });
    }, []);

    useEffect(() => {
        const messageListener = (nativeEvent) => {
            const data = nativeEvent?.data;
            if (typeof data === "string") {
                const object = JSON.parse(data);
    
                if (object.source === "react_native") {
                    // Handle the message
                    if (object.message === "action_play")
                    {
                        setIsPlaying(true);
                    }
                    else if (object.message === "action_pause")
                    {
                        setIsPlaying(false);
                    }
                }
            }
        };
    
        document.addEventListener('message', messageListener);
    
        return () => {
            document.removeEventListener('message', messageListener);
        };
    }, []);

    return (
        <>
            <div className='radio_player_widget'>
                <div className='picture'>
                    <img src={LogoImage} alt='Logo' />
                </div>
                <div className='text'>
                    <h2 className='title'>
                        Brava 1480AM
                    </h2>
                    <h2 className='live'>
                        LIVE
                    </h2>
                </div>
                <button onClick={togglePlayer} disabled={isLoading}>
                    {
                        isPlaying
                        ? <PauseIcon />
                        : <PlayIcon />
                    }
                </button>

                {
                    isLoading && (
                        <div className='loader'>
                            <BarLoader />
                        </div>
                    )
                }
            </div>
            <audio controls ref={audioRef} preload='none' className='ghost_audio'>
                {
                    source !== "" && <source src={source} type="audio/mpeg" />
                }
            </audio>
        </>
    );
}