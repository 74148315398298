import { useManageAdmin } from "../ManageAdminsScreen/ManageAdminsContext";
import { UserProfilePicture } from "../UserProfilePicture/UserProfilePicture";

export const AdminItem = ({user}) => {
    //HOOKS
    const { setAdminToDelete } = useManageAdmin();

    return (
        <div className="admin_user_item">
            <div className='main_info'>
                <div className='pic'>
                    <UserProfilePicture userId={user.id} />
                </div>
                <div className='info'>
                    <h2>
                        @{
                            user.username
                        }
                    </h2>
                    <span>
                        {
                            user.status.chat_banned ? "bloqueado" : ""
                        }
                    </span>
                </div>
            </div>
            <div className='actions'>
                <button className='light_btn' onClick={() => setAdminToDelete(user)}>
                    Quitar
                </button>
            </div>
        </div>
    );
}