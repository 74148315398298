import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { useAuth } from "../../contexts/AuthContext"
import { UserProfilePicture } from "../UserProfilePicture/UserProfilePicture";

export const ProfilePicture = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
            reload,
        }
    });

    //HOOKS
    const { user } = useAuth();

    //REF
    const imageRef = useRef();

    //FUNCTIONS
    const reload = () => {
        imageRef.current.reload();
    }

    //EFFECTS
    useEffect(() => {
        if (imageRef.current)
            reload();
    }, [user]);

    return (
        <>
            {
                user && <UserProfilePicture userId={user.id} ref={imageRef} />
            }  
        </>
    );
});