import './admin_banners_screen.css';
import { APP_SCREENS, useAppManager } from "../../contexts/AppManagerContext";
import { ScreenContainer } from "../ScreenContainer/ScreenContainer";
import { useServer } from '../../contexts/ServerContext';
import { useEffect, useRef, useState } from 'react';
import { endpoints } from '../../env';
import { AdminBannerItem } from './AdminBannerItem';
import { BarLoader } from '../BarLoader/BarLoader';

export const AdminBannersScreen = () => {
    //HOOKS
    const { getRequest } = useServer();
    const { currentAppScreen, GoToPrevius } = useAppManager();
    const { postRequest } = useServer();

    //STATES
    const [loading, setLoading] = useState(false);
    const [banners, setBanners] = useState([]);
    const [previewImage, setPreviewImage] = useState("");
    const [imageError, setImageError] = useState("");

    //REFS
    const fileRef = useRef();

    //FUNCTIONS
    const loadBanners = () => {
        if (loading)
        {
            return;
        }

        setLoading(true);

        getRequest(endpoints.get_banners, {}, (res) => {
            setLoading(false);
            setBanners(res[1].banners);
        });
    }

    const uploadBanner = () => {
        if (loading)
            {
                return;
            }
    
            if (!checkFormat())
            {
                setImageError("Solo se permiten formatos png y jpg");
                return;
            }
    
            setLoading(true);
            setImageError("");
            let fd = new FormData();
            fd.append("image", fileRef.current.files[0]);
    
            postRequest(endpoints.upload_banner, fd, (res) => {
                setLoading(false);
                fileRef.current.value = "";
                setPreviewImage("");
                loadBanners();
            });
    }

    const checkFormat = () => {
        return (fileRef.current.files[0].type === "image/png" || fileRef.current.files[0].type === "image/jpeg" || fileRef.current.files[0].type === "image/jpg");
    }

    //EVENT HANDLERS
    const imageLoaded = (e) => {
        if (!checkFormat())
            {
                setImageError("Solo se permiten formatos png y jpg");
                setPreviewImage("");
                return;
            }
            setImageError("");
            setPreviewImage(URL.createObjectURL(e.target.files[0]));
    }

    //EFFECTS
    useEffect(() => {
        if (currentAppScreen !== APP_SCREENS.ADMIN_BANNERS)
            return;

        loadBanners();
    }, [currentAppScreen]);

    return (
        <ScreenContainer screen={APP_SCREENS.ADMIN_BANNERS} id={'admin_banners_screen'}>
            <div className='banners_container'>
                <h2>
                   Agregar banner 
                </h2>
                <div className='input_container'>
                    <label>
                        Foto banner
                    </label>
                    <input type='file' ref={fileRef} onChange={imageLoaded}/>
                    <span>
                        {imageError}
                    </span>
                </div>
                <img src={previewImage} alt='' />
                <div className='action'>
                    <button className='light_btn' disabled={loading} onClick={GoToPrevius}>
                        Volver
                    </button>
                    <button className='light_btn' disabled={loading} onClick={uploadBanner}>
                        Agregar
                    </button>
                </div>
                {
                    loading && (
                        <div className='loader'>
                            <BarLoader />
                        </div>
                    )
                }
            </div>
            <div className='banners_container'>
                <h2>
                   Administrar banners 
                </h2>
                {
                    banners && banners.map((banner) => {
                        return <AdminBannerItem banner={banner} key={"admin_banner_" + banner.id} deleteCallback={loadBanners} />
                    })
                }
                <div className='action'>
                    <button className='light_btn' disabled={loading} onClick={GoToPrevius}>
                        Volver
                    </button>
                </div>
            </div>
        </ScreenContainer>
    );
}