import './manage_admins_screen.css';
import { APP_SCREENS } from "../../contexts/AppManagerContext"
import { ScreenContainer } from "../ScreenContainer/ScreenContainer"
import { BarLoader } from "../BarLoader/BarLoader";
import { AdminItem } from '../AdminItem/AdminItem';
import { useAuth } from '../../contexts/AuthContext';
import { AdminDeleteModal } from '../AdminDeleteModal/AdminDeleteModal';
import { ManageAdminsProvider, useManageAdmin } from './ManageAdminsContext';
import { useState } from 'react';
import { BackArrowIcon } from '../../assets/icons/BackArrowIcon';
import { useServer } from '../../contexts/ServerContext';
import { manageAdminsEndpoinst, userAdminEndpoints } from '../../env';

export const ManageAdminsScreenWrapper = () => {
    return (
        <ManageAdminsProvider>
            <ManageAdminsScreen />
        </ManageAdminsProvider>
    )
}

const ManageAdminsScreen = () => {
    //HOOKS
    const { user: loggedUser } = useAuth();
    const {
        users,
        loader,
        loadAdmins,
    } = useManageAdmin();
    const { getRequest, postRequest } = useServer(); 

    //STATES
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [addAdminCollapsed, setAddAdminCollapsed] = useState(false);
    const [addAdminLoading, setAddAdminLoading] = useState(false);

    //FUNCTIONS
    const cancelAddAdmin = () => {
        setEmail("");
        setAddAdminCollapsed(false);
    }

    const addAdmin = () => {
        if (addAdminLoading)
        {
            return;
        }

        setAddAdminLoading(true);
        setEmailError("");
        const data = {
            email,
        }

        getRequest(userAdminEndpoints.user_get_by_email, data, (res) => {
            if (res[0])
            {
                res = res[1];
                if (res.success)
                {
                    postRequest(manageAdminsEndpoinst.add_admin, {id : res.user.id}, (response) => {
                        setAddAdminLoading(false);
                        if (response[0])
                        {
                            loadAdmins();
                            cancelAddAdmin();
                        }
                        else
                        {
                            setEmailError("Server error. please try again in a bit");
                        }
                    });
                }
                else
                {
                    setAddAdminLoading(false);
                    setEmailError("No hay ningun usuario con este correo.");
                }
            }
            else
            {
                setAddAdminLoading(false);
                setEmailError("Server error. please try again in a bit");
            }
        });
    }

    return (
        <>
            <ScreenContainer screen={APP_SCREENS.MANAGE_ADMINS} id={'manage_admins_screen'}>
                <div className={addAdminCollapsed ? 'search_user_container collapsed' : 'search_user_container'}>
                    <div className='header'>
                        <h2>
                            Agregar administrador
                        </h2>
                        <button onClick={() => setAddAdminCollapsed(!addAdminCollapsed)}>
                            <BackArrowIcon />
                        </button>
                    </div>
                    <div className='input_container'>
                        <label>
                            Correo
                        </label>
                        <input type='email' placeholder='email@domain.com' value={email} onChange={(e) => setEmail(e.target.value)} />
                        <span>
                            {emailError}
                        </span>
                    </div>
                    <div className='actions'>
                        <div>

                        </div>
                        <div className='btns'>
                            <button className='light_btn' disabled={addAdminLoading} onClick={cancelAddAdmin}>
                                Cancelar
                            </button>
                            <button className='light_btn' disabled={addAdminLoading} onClick={addAdmin}>
                                Agregar
                            </button>
                        </div>
                    </div>

                    {
                        addAdminLoading && (
                            <div className='loader'>
                                <BarLoader />
                            </div>
                        )
                    }
                </div>

                <div className='users_list'>
                    <div className='header'>
                        <h2>
                            Administradores
                        </h2>
                        <button className='light_btn' onClick={loadAdmins}>
                            Recargar
                        </button>
                        {
                            loader && (
                                <div className='loader'>
                                    <BarLoader />
                                </div>
                            )
                        }
                    </div>

                    {
                        users && users.map((user) => {
                            if (loggedUser.id === user.id)
                            {
                                return <></>
                            }
                            return <AdminItem user={user} key={'admin_item_' + user.id} />
                        })
                    }
                </div>
            </ScreenContainer>
            <AdminDeleteModal />
        </>
    );
}