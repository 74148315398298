import { createContext, useContext, useEffect, useState } from "react";
import { useServer } from "../../contexts/ServerContext";
import { manageAdminsEndpoinst } from "../../env";
import { APP_SCREENS, useAppManager } from "../../contexts/AppManagerContext";

const ManageAdminsContext = createContext(undefined);

export const ManageAdminsProvider = ({children}) => {
    //HOOKS
    const { getRequest } = useServer();
    const { currentAppScreen } = useAppManager();

    //STATES
    const [users, setUsers] = useState([]);
    const [loader, setLoader] = useState(false);
    const [adminToDelete, setAdminToDelete] = useState(null);

    //FUNCTIONS
    const loadAdmins = () => {
        if (loader)
        {
            return;
        }

        setLoader(true);

        getRequest(manageAdminsEndpoinst.list_admins, {}, (res) => {
            setLoader(false);

            if (res[0])
            {
                setUsers(res[1].admins);
            }
        });
    }

    //STATES
    useEffect(() => {
        if (currentAppScreen === APP_SCREENS.MANAGE_ADMINS)
        {
            loadAdmins();
        }
    }, [currentAppScreen]);

    const value = {
        users,
        loader,
        setLoader,
        loadAdmins,
        adminToDelete,
        setAdminToDelete,
    }

    return (
        <ManageAdminsContext.Provider value={value}>
            {children}
        </ManageAdminsContext.Provider>
    )
}

export const useManageAdmin = () => useContext(ManageAdminsContext);