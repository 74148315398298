import './admin_banner_item.css';
import { backendEnv, endpoints } from "../../env";
import { useServer } from '../../contexts/ServerContext';
import { useRef, useState } from 'react';
import { BarLoader } from '../BarLoader/BarLoader';

export const AdminBannerItem = ({banner, deleteCallback}) => {
    //HOOKS
    const { postRequest } = useServer();

    //STATES
    const [loading, setLoading] = useState(false);

    //REFS
    const confirmationRef = useRef();

    //FUNCTIONS
    const postDelete = () => {
        if (loading)
            return;

        setLoading(true);

        postRequest(endpoints.delete_banner, { id: banner.id}, (res) => {
            setLoading(false);
            deleteCallback();
        });
    }

    return (
        <div className="admin_banner">
            <img src={backendEnv.serverUrl + banner.image} alt="" />
            <button className='delete light_btn' disabled={loading} onClick={() => confirmationRef.current.classList.toggle('active')}>
                Eliminar
            </button>
            
            <div className='loader'>
                {
                    loading && (
                        <BarLoader />
                    )
                }
            </div>

            <div className='confirmation_delete' ref={confirmationRef}>
                <h2>
                    ¿Seguro que quieres eliminar el banner?
                </h2>
                <div>
                    <button className='light_btn' disabled={loading} onClick={() => confirmationRef.current.classList.toggle('active')}>
                        Cancelar
                    </button>
                    <button className='delete light_btn' disabled={loading} onClick={postDelete}>
                        Eliminar
                    </button>
                </div>
                
            </div>
        </div>
    );
}