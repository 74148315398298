import './main_screen.css';
import { APP_SCREENS, useAppManager } from "../../contexts/AppManagerContext";
import { ScreenContainer } from "../ScreenContainer/ScreenContainer";
import { NewsSliderItem } from '../NewsSliderItem/NewsSliderItem';
import { useNews } from '../../contexts/NewsContext';
import { BannerSlider } from '../BannerSlider/BannerSlider';
import { Link } from 'react-router-dom';

export const MainScreen = () => {
    //HOOKS
    const { lastNews } = useNews();
    const { os } = useAppManager();
    
    return (
        <ScreenContainer screen={APP_SCREENS.MAIN} id={"main_screen"}>
            <div className='latest_news_container'>
                <h1>
                    Últimas { os === "android" ? "novedades" : "noticias" }
                </h1>
                <div className='news_list'>
                    {
                        os !== "android" && lastNews.map((news) => {
                            return <NewsSliderItem key={"last_new_item_" + news.id} news={news} />
                        })
                    }
                </div>
            </div>

            <BannerSlider />

            <div className='social_media_section'>
                <h2>
                    Síguenos en nuestras redes sociales.
                </h2>
                <div className='social_media_container'>
                    <Link to={'https://www.tiktok.com/@brava1480'} target='_blank'>
                        <i className="fa-brands fa-tiktok"></i>
                    </Link>
                    <Link to={"https://www.instagram.com/brava1480am/"} target='_blank'>
                        <i className="fa-brands fa-instagram"></i>
                    </Link>
                    <Link to={"https://web.facebook.com/brava1480?locale=es_LA"} target='_blank'>
                        <i className="fa-brands fa-facebook-f"></i>
                    </Link>
                </div>
            </div>
        </ScreenContainer>
    );
}