import './manage_banned_screen.css';
import { APP_SCREENS } from "../../contexts/AppManagerContext";
import { ScreenContainer } from "../ScreenContainer/ScreenContainer";
import { BarLoader } from '../BarLoader/BarLoader';
import { useAuth } from '../../contexts/AuthContext';
import { ManageBannedProvider, useManageBanned } from './ManageBannedContext';
import { BannedUserItem } from '../BannedUserItem/BannedUserItem';
import { UnbanUserModal } from '../UnbanUserModal/UnbanUserModal';

export const ManageBannedScreenWrapper = () => {
    return (
        <ManageBannedProvider>
            <ManageBannedScreen />
        </ManageBannedProvider>
    );
}

const ManageBannedScreen = () => {
    //HOOKS
    const { user: loggedUser } = useAuth();
    const {
        users,
        loader,
        loadBannedUsers,
    } = useManageBanned();

    return (
        <>
            <ScreenContainer screen={APP_SCREENS.MANAGE_BANNED} id={'manage_banned_screen'}>
                <div className='users_list'>
                    <div className='header'>
                        <h2>
                            Bloqueados
                        </h2>
                        <button className='light_btn' onClick={loadBannedUsers}>
                            Recargar
                        </button>
                        {
                            loader && (
                                <div className='loader'>
                                    <BarLoader />
                                </div>
                            )
                        }
                    </div>

                    {
                        users && users.map((user) => {
                            if (loggedUser.id === user.id)
                            {
                                return <></>
                            }
                            return <BannedUserItem user={user} key={'banned_user_item_' + user.id} />
                        })
                    }
                </div>
            </ScreenContainer>
            <UnbanUserModal />
        </>
    );
}