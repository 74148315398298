import './news_post_screen.css';
import { APP_SCREENS, useAppManager } from "../../contexts/AppManagerContext";
import { useNews } from "../../contexts/NewsContext";
import { ScreenContainer } from "../ScreenContainer/ScreenContainer";
import { BackArrowIcon } from '../../assets/icons/BackArrowIcon';

export const NewsPostScreen = () => {
    //HOOKS
    const { currentNews } = useNews();
    const { GoToPrevius } = useAppManager();

    return (
        <ScreenContainer screen={APP_SCREENS.NEWS_VIEW} id={"news_view_post"}>
            <div className='header' id='news_post_view_header'>
                <button onClick={GoToPrevius}>
                    <BackArrowIcon />
                </button>
            </div>
            <div className='news_post_container'>
                <h1 dangerouslySetInnerHTML={{__html: currentNews && currentNews.title}}>
                </h1>
                <div className='news_post'>
                    <img src={currentNews ? currentNews.image : ""} alt={"portada"} />
                    <div className="content" dangerouslySetInnerHTML={{__html: currentNews ? currentNews.content : ""}}>

                    </div>
                </div>
                <button className='light_btn' onClick={GoToPrevius}>
                    Volver
                </button>
            </div>
        </ScreenContainer>
    );
}