import './auth_modal.css';
import { CloseIcon } from '../../assets/icons/CloseIcon';
import { useAuthPage } from '../../pages/AuthPage/AuthPageContext';

export const AuthModal = ({children, title, modal}) => {
    //HOOKS
    const { activeModal, setActiveModal } = useAuthPage();

    return (
        <div className={activeModal === modal ? 'auth_modal active' : 'auth_modal'}>
            <div className='header'>
                <div>

                </div>
                <h2>
                    {title}
                </h2>
                <div></div>
                <button className='close' onClick={() => setActiveModal(null)}>
                    <CloseIcon />
                </button>
            </div>
            <div className='body'>
                {
                    children
                }
            </div>
        </div>
    );
}