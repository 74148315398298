import './delete_message_modal.css';
import { useEffect, useState } from 'react';
import { useUserAdminActions } from '../../contexts/UserAdminActionsContext';
import { BarLoader } from '../BarLoader/BarLoader';
import { useServer } from '../../contexts/ServerContext';
import { endpoints } from '../../env';
import { useAppManager } from '../../contexts/AppManagerContext';

export const DeleteMessageModal = () => {
    //HOOKS
    const { messageToDelete, setMessageToDelete } = useUserAdminActions();
    const { postRequest } = useServer();
    const { setModalOnClose } = useAppManager();

    //STATES
    const [loading, setLoading] = useState(false);

    //FUNCTIONS
    const deleteMessage = () => {
        setLoading(true);

        const data = {
            message_id: messageToDelete.id,
        }

        postRequest(endpoints.delete_message, data, (res) => {
            setLoading(false);
            setMessageToDelete(null);
        });
    }

    const close = () => {
        setMessageToDelete(null);
        setModalOnClose(null);
    }

    return (
        <div className={messageToDelete === null ? 'delete_modal' : 'delete_modal active'}>
            <div className='delete_form'>
                <h2>
                    ¿Estas seguro de borrar el mensaje?
                </h2>
                <p>
                    Usuario: {messageToDelete && messageToDelete.author.username}
                    <br />
                    Esta acción no se puede deshacer.
                </p>

                <div className='actions'>
                    <div></div>
                    <div>
                        <button className='cancel' disabled={loading} onClick={close}>Cancelar</button>
                        <button className='delete' disabled={loading} onClick={deleteMessage}>Borrar</button>
                    </div>
                    {
                        loading && (
                            <div className='loader'>
                                <BarLoader />
                            </div>
                        
                        )
                    }
                </div>
            </div>
        </div>
    );
}