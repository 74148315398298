import './news_slider_item.css';
import { useNews } from '../../contexts/NewsContext';

export const NewsSliderItem = ({news}) => {
    //HOOKS
    const { openNews } = useNews();

    return (
        <>
            <div className='news_item_container' onClick={() => openNews(news)}>
                <div className='news_item'>
                    <img src={news.image} alt={"portada"} />
                </div>
                <p dangerouslySetInnerHTML={{__html: news.title}}>
                    
                </p>
            </div>
        </>
    );
}