export const DeleteIcon = () => {
    return (
        <svg viewBox="0 0 364 384" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M348.46 74.5H15.5415" stroke="white" strokeWidth="30" strokeLinecap="round"/>
            <path d="M315.817 123.458L306.808 258.566C303.342 310.558 301.609 336.554 284.67 352.401C267.73 368.25 241.676 368.25 189.569 368.25H174.425C122.317 368.25 96.2631 368.25 79.3234 352.401C62.3838 336.554 60.6507 310.558 57.1844 258.566L48.1772 123.458" stroke="white" strokeWidth="30" strokeLinecap="round"/>
            <path d="M133.042 172.417L142.833 270.333" stroke="white" strokeWidth="30" strokeLinecap="round"/>
            <path d="M230.958 172.417L221.167 270.333" stroke="white" strokeWidth="30" strokeLinecap="round"/>
            <path d="M74.2915 74.5C75.3858 74.5 75.933 74.5 76.429 74.4875C92.5547 74.0788 106.781 63.8253 112.268 48.6563C112.437 48.1896 112.61 47.6706 112.956 46.6323L114.857 40.9285C116.48 36.0595 117.292 33.6249 118.368 31.5577C122.663 23.3105 130.608 17.5838 139.79 16.1176C142.092 15.75 144.658 15.75 149.791 15.75H214.209C219.341 15.75 221.909 15.75 224.21 16.1176C233.392 17.5838 241.337 23.3105 245.632 31.5577C246.709 33.6249 247.52 36.0595 249.143 40.9285L251.045 46.6323C251.389 47.6693 251.564 48.19 251.732 48.6563C257.219 63.8253 271.445 74.0788 287.572 74.4875C288.067 74.5 288.613 74.5 289.708 74.5" stroke="white" strokeWidth="30"/>
        </svg>
    );
}