import axios from "axios";
import { createContext, useContext } from "react";
import { backendEnv } from "../env";
import { useAuth } from "./AuthContext";

const ServerContext = createContext(undefined);

export const ServerProvider = ({children}) => {
    //HOOKS
    const api = axios.create({
        baseURL: backendEnv.serverUrl,
        withCredentials: true,
    });

    const { getAuthToken } = useAuth();

    //FUNCTIONS
    const getRequest = (url, data, callback) => {
        let requestOptions =  {
            params: data,
            headers: {
                'auth_token': getAuthToken()
            }
        };

        api.get(url, requestOptions).then(res => {
            callback([true, res.data]);
        }).catch((res) => {
            callback([false, res]);
        });
    }

    const postRequest = (url, data, callback) => {
        let requestOptions =  {
            headers: {
                'auth_token': getAuthToken()
            }
        };

        api.post(url, data, requestOptions).then(res => {
            callback([true, res.data]);
        }).catch((res) => {
            callback([false, res]);
        });
    }

    const values = {
        getRequest,
        postRequest,
    }

    return (
        <ServerContext.Provider
            value={values}
        >
            {children}
        </ServerContext.Provider>
    );
}

export const useServer = () => useContext(ServerContext);