import './user_admin_actions.css';
import { useUserAdminActions } from '../../contexts/UserAdminActionsContext';
import { BarLoader } from '../BarLoader/BarLoader';
import { useState } from 'react';
import { useServer } from '../../contexts/ServerContext';
import { endpoints } from '../../env';
import { UserProfilePicture } from '../UserProfilePicture/UserProfilePicture';

export const UserAdminActionsModal = () => {
    //HOOKS
    const {
        userToAdministrate,
        loadingUser,
        currentSelectedUser,
        setUserToAdministrate
    } = useUserAdminActions();

    const { postRequest } = useServer();

    //STATES
    const [actionLoading, setActionLoading] = useState(false);

    //FUNCTIONS
    const banUserFromChat = () => {
        if (actionLoading)
        {
            return;
        }

        setActionLoading(true);
        postRequest(endpoints.ban_user_from_chat, {user_id: userToAdministrate}, (res) => {
            setActionLoading(false);
            setUserToAdministrate(null);
        });
    }

    const unBanUserFromChat = () => {
        if (actionLoading)
        {
            return;
        }

        setActionLoading(true);
        postRequest(endpoints.unban_user_from_chat, {user_id: userToAdministrate}, (res) => {
            setActionLoading(false);
            setUserToAdministrate(null);
        });
    }

    return ( 
        <div className={userToAdministrate !== null ? 'user_admin_actions_modal active' : 'user_admin_actions_modal'}>
            <div className='container'>
                <div className='header'>
                    <h2>
                        Acciones administrativas
                    </h2>
                    {
                        (loadingUser || actionLoading) && (
                            <div className='loader'>
                                <BarLoader />
                            </div>
                        )
                    }
                </div>
                <div className='body'>
                    <div className='profile_pic'>
                        {
                            userToAdministrate != null && (
                                <UserProfilePicture userId={userToAdministrate} />
                            )
                        }
                    </div>
                    <p className='user'>
                        <span>Usuario:</span> @{currentSelectedUser && currentSelectedUser.username}
                    </p>
                    <p className='user'>
                        <span>Nombre:</span> {currentSelectedUser && currentSelectedUser.name}
                    </p>
                    <p className='user'>
                        <span>Correo:</span> {currentSelectedUser && currentSelectedUser.email}
                    </p>
                    <p className='user'>
                        <span>Id:</span> {currentSelectedUser && currentSelectedUser.id}
                    </p>
                    <div className='admin_actions_list'>
                        {
                            currentSelectedUser && currentSelectedUser.status.chat_banned&& (
                                <button className='light_btn' onClick={unBanUserFromChat}>
                                    Desbloquear usuario del chat
                                </button>
                            )
                        }
                        {
                            currentSelectedUser && !currentSelectedUser.status.chat_banned && (
                                <button className='light_btn' onClick={banUserFromChat}>
                                    Bloquear usuario del chat
                                </button>
                            )
                        }
                    </div>
                </div>
                <div className='actions'>
                    <div></div>
                    <div className='buttons'>
                        <button className='light_btn' disabled={actionLoading} onClick={() => setUserToAdministrate(null)}>
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}