import { useState } from "react";
import { useManageBanned } from "../ManageBannedScreen/ManageBannedContext";
import { BarLoader } from "../BarLoader/BarLoader";
import { useServer } from "../../contexts/ServerContext";
import { userAdminEndpoints } from "../../env";

export const UnbanUserModal = () => {
    //HOOKS
    const {
        userToUnban,
        setUserToUnban,
        loadBannedUsers,
    } = useManageBanned();
    const { postRequest } = useServer();

    //STATES
    const [loading, setLoading] = useState(false);

    //FUNCTION
    const unBanUser = () => {
        if (loading)
        {
            return;
        }

        setLoading(true);

        postRequest(userAdminEndpoints.unban_user, { user_id: userToUnban.id }, (res) => {
            setLoading(false);
            loadBannedUsers();
            setUserToUnban(null);
        });
    }

    return (
        <div className={userToUnban === null ? 'delete_modal' : 'delete_modal active'}>
            <div className='delete_form'>
                <h2>
                    ¿Estas seguro de desbloquear el usuario?
                </h2>
                <p>
                    Usuario: {userToUnban && userToUnban.username}
                    <br />
                    El usuario sera desbloqueado y podra interactuar en el chat.
                </p>

                <div className='actions'>
                    <div></div>
                    <div>
                        <button className='cancel' disabled={loading} onClick={() => setUserToUnban(null)}>Cancelar</button>
                        <button className='delete' disabled={loading} onClick={unBanUser}>Desbloquear</button>
                    </div>
                    {
                        loading && (
                            <div className='loader'>
                                <BarLoader />
                            </div>
                        
                        )
                    }
                </div>
            </div>
        </div>
    );
}