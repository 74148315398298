import { createContext, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const AppManagerContext = createContext(undefined);

export const APP_SCREENS = {
    MAIN: 0,
    CHAT: 1,
    NEWS_VIEW: 2,
    NEWS_LIST: 3,
    USER_MENU: 4,
    UPDATE_ACCOUNT: 5,
    UPDATE_PASSWORD: 6,
    UPDATE_PICTURE: 7,
    ADMIN_MAIN: 8,
    ADMIN_BANNERS: 9,
    ADMIN_USERS: 10,
    MANAGE_ADMINS: 11,
    MANAGE_BANNED: 12,
}

const convertToLocalTime = (utcDateTime) => {
    const utcDate = new Date(utcDateTime);
    const localDate = new Date(utcDate.getTime() + (utcDate.getTimezoneOffset() * 60000));

    const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    };

    return localDate.toLocaleString('en-US', options); // Formats the date to desired format
};

export const AppManagerProvider = ({ children }) => {
    //HOOKS
    const [searchParams, setSearchParams] = useSearchParams();

    //STATES
    const [previusAppScreen, setPreviusAppScreen] = useState([]);
    const [currentAppScreen, setCurrentAppScreen] = useState(APP_SCREENS.MAIN);
    const [modalOnClose, setModalOnClose] = useState(null);
    const [os, setOs] = useState("");

    //FUNCTIONS
    const GoToPrevius = () => {
        if (previusAppScreen.length > 0) {
            setCurrentAppScreen(previusAppScreen[previusAppScreen.length - 1]);
            const newPrev = previusAppScreen.slice(0, -1);
            setPreviusAppScreen(newPrev);
        } else {
            setCurrentAppScreen(APP_SCREENS.MAIN);
        }
    }

    const changeAppScreen = (screen) => {
        const index = previusAppScreen.indexOf(screen);

        if (index > -1) {
            let prevArray = previusAppScreen;
            for (let i = prevArray.length - 1; i >= index; i--) {
                prevArray.splice(i, 1);
            }
            setPreviusAppScreen(prevArray);
            setCurrentAppScreen(screen);
        } else {
            setPreviusAppScreen((prev) => [...prev, currentAppScreen]);
            setCurrentAppScreen(screen);
        }
    }

    //EFFECTS
    useEffect(() => {
        const messageListener = (nativeEvent) => {
            const data = nativeEvent?.data;
            
            if (typeof data === "string") {
                const object = JSON.parse(data);
                if (object.source === "react_native") {
                    // Handle the message
                    if (object.message === "action_go_back")
                    {
                        GoToPrevius();
                    }
                }
            }
        };
    
        document.addEventListener('message', messageListener);
    
        return () => {
            document.removeEventListener('message', messageListener);
        };
    }, [previusAppScreen]);

    useEffect(() => {
        setOs(searchParams.get('os'));
    }, []);

    const values = {
        GoToPrevius,
        currentAppScreen,
        changeAppScreen,
        modalOnClose,
        setModalOnClose,
        os,
    }

    return (
        <AppManagerContext.Provider
            value={values}
        >
            {children}
        </AppManagerContext.Provider>
    )
}

export const useAppManager = () => useContext(AppManagerContext);