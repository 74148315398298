import './banner_slider.css';
import { useEffect, useState } from "react";
import { useServer } from "../../contexts/ServerContext"
import { endpoints } from "../../env";
import { BannerItem } from "./BannerItem";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import { Autoplay } from 'swiper/modules';
import 'swiper/css';

export const BannerSlider = () => {
    //HOOKS
    const { getRequest } = useServer();

    //STATES
    const [banners, setBanners] = useState([]);

    //FUNCTIONS
    const loadBanners = () => {
        getRequest(endpoints.get_banners, {}, (res) => {
            if (res[0])
            {
                res = res[1];
                setBanners(res.banners);
            }
        });
    }

    //EFFECTS
    useEffect(() => {
        loadBanners();
    }, []);

    return (
        <>
            <div className="banner_slider">
                <Swiper
                    spaceBetween={30}
                    loop={true}
                    speed={1000}
                    autoplay={{
                        delay: 3600,
                        disableOnInteraction: false,
                    }}
    
                    modules={[Autoplay]}
                >
                    {
                        banners.map((banner) => {
                            return <SwiperSlide><BannerItem banner={banner} key={"banner_" + banner.id} /></SwiperSlide> 
                        })
                    }
                </Swiper>
            </div>
        </>
    )
}