import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useEffect } from "react";

export const ProtectedRoute = ({path}) => {
    //HOOKS
    const navigate = useNavigate();
    const location = useLocation();
    const { isAuth } = useAuth();

    useEffect(() => {
        if (isAuth === null) {
            return;
        }

        if (location.pathname === path && !isAuth) {
            navigate("/", { replace: true });
        }
    }, [location, isAuth]);

    return <></>;
} 