export const backendEnv = {
    serverUrl: "https://appbackend.brava1480.com",
    //serverUrl: "http://192.168.1.5:8000",
    //serverUrl: "https://bravabackend.atzecms.website",
    globalChatChannel: "globalchat",
}

export const RadioEnv = {
    streamUrl: "https://appbackend.brava1480.com/radiostream.php",
}

export const endpoints = {
    auth_check : "/api/v1/platform_user/auth/check",
    login: "/api/v1/platform_user/login",
    guest_login: "/api/v1/platform_user/guest",
    regiser: "/api/v1/platform_user/register",
    logout: "/api/v1/platform_user/logout",

    user_update: '/api/v1/platform_user/update',
    user_upload_picture: '/api/v1/platform_user/update/picture',
    user_delete_picture: '/api/v1/platform_user/delete/picture',

    //GLOBAL CHAT
    send_message: "/api/v1/globlal_chat/message/send",
    get_chat: "/api/v1/global_chat/get",

    //ADMIN CHAT
    delete_message: '/api/v1/global_chat/admin/message/delete',
    get_user: '/api/v1/admin/platform_users/get',
    ban_user_from_chat: '/api/v1/admin/platform_users/ban',
    unban_user_from_chat: '/api/v1/admin/platform_users/unban',

    //NEWS
    get_last_news: '/api/v1/news/get_last_posts',
    get_news: '/api/v1/news/get_post',

    //BANNERS
    get_banners: '/api/v1/banners/get',
    delete_banner: '/api/v1/banners/delete',
    upload_banner: '/api/v1/banners/create',
}

export const platformUserEndpoints = {
    delete_account: '/api/v1/platform_user/account/delete',
}

export const userAdminEndpoints = {
    users_list: '/api/v1/admin/platform_users/list',
    users_search: '/api/v1/admin/platform_users/search',
    user_get_by_email: '/api/v1/admin/platform_users/get/email',
    users_list_banned: '/api/v1/admin/platform_users/list/banned',
    unban_user: '/api/v1/admin/platform_users/unban',
}

export const manageAdminsEndpoinst = {
    list_admins: '/api/v1/admin/platform_users/admins/list',
    remove_admin: '/api/v1/admin/platform_users/admins/remove',
    add_admin: '/api/v1/admin/platform_users/admins/add',
}

export const appleIdEndpoints = {
    signin_with_apple: '/api/v1/platform_user/apple/signin',
}
