import './chat_screen.css';
import { APP_SCREENS, useAppManager } from "../../contexts/AppManagerContext";
import { ScreenContainer } from "../ScreenContainer/ScreenContainer";
import { ChatMessage } from '../ChatMessage/ChatMessage';
import { ChatInput } from '../ChatInput/ChatInput';
import { useChat } from '../../contexts/ChatContext';
import { useAuth } from '../../contexts/AuthContext';
import { useEffect, useRef } from 'react';
import { SelectedMessageMenu } from '../SelectedMessageMenu/SelectedMessageMenu';

export const ChatScreen = () => {
    const { messages, autoScrolling, setAutoScrolling } = useChat();
    const { user } = useAuth();
    const { currentAppScreen } = useAppManager(); 

    //REFS
    const bottomRef = useRef();

    useEffect(() => {
        if (bottomRef.current && autoScrolling)
        {
            bottomRef.current.scrollIntoView({behavior: "smooth"});
        }
    }, [messages]);

    useEffect(() => {
        if (currentAppScreen !== APP_SCREENS.CHAT)
        {
            if (bottomRef?.current)
            {
                bottomRef.current.scrollIntoView({behavior: "smooth"});
            }
        }
    }, [currentAppScreen]);

    //EVENT HANDLERS
    const onScroll = (event) => {
        const bottomOffset = event.target.scrollHeight - event.target.clientHeight;
        const offsetThreshold = 40;

        if (event.target.scrollTop < (bottomOffset - offsetThreshold))
        {
            setAutoScrolling(false);
        }
        else
        {
            setAutoScrolling(true);
        }
    }

    return (
        <ScreenContainer screen={APP_SCREENS.CHAT}>
            <div className="chat_container">
                <SelectedMessageMenu />
                {
                    user && (
                        <>
                            <div className='chat_body' onScroll={onScroll}>
                                {
                                    messages.map((message) => {
                                        return <ChatMessage message={message} own={message.author.id === user.id} key={"message_" + message.id} />
                                    })
                                }
                                <div ref={bottomRef} id='bottom_chat_dummy'>
                                    
                                </div>
                            </div>
                        </>
                    )
                }
                <ChatInput />
            </div>
        </ScreenContainer>
    );
}