import './update_password_screen.css';
import { APP_SCREENS, useAppManager } from "../../contexts/AppManagerContext";
import { ScreenContainer } from "../ScreenContainer/ScreenContainer";
import { useAuth } from "../../contexts/AuthContext";
import { BarLoader } from '../BarLoader/BarLoader';
import { useEffect, useState } from 'react';
import { useServer } from '../../contexts/ServerContext';
import { endpoints } from '../../env';
import { ProfilePicture } from '../ProfilePicture/ProfilePicture';

export const UpdatePasswordScreen = () => {
    //HOOKS
    const { user } = useAuth();
    const { GoToPrevius, currentAppScreen } = useAppManager();
    const { postRequest } = useServer();

    //STATES
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [loading, setLoading] = useState(false);

    //FUNCTIONS
    const postUpdatePassword = () => {
        if (loading)
        {
            return;
        }

        if (password !== repeatPassword)
        {
            setPasswordError("Las contraseñas no coinciden");
            return;
        }

        if (password === "")
        {
            setPasswordError("La contraseña no puede estar vacia");
            return;
        }

        setPasswordError("");
        setLoading(true);

        const data = {
            password
        }

        postRequest(endpoints.user_update, data, (res) => {
            setLoading(false);

            if (res[0])
            {
                GoToPrevius();
            }
        });
    }

    //EFFECTS
    useEffect(() => {
        if (currentAppScreen === APP_SCREENS.UPDATE_PASSWORD)
        {
            setPassword("");
            setRepeatPassword("");
            setPasswordError("");
        }
    }, [currentAppScreen]);

    return (
        <ScreenContainer screen={APP_SCREENS.UPDATE_PASSWORD} id={"update_password_screen"}>
            <div className='profile_container'>
                <div className='profile_pic'>
                    <ProfilePicture />
                </div>
                <h2 className='username'>
                    @{
                        user && user.username
                    }
                </h2>
            </div>

            <div className="form_container">
                <div className='input_container'>
                    <label>
                        Nueva contraseña
                    </label>
                    <input type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                    <span>
                        {passwordError}
                    </span>
                </div>
                <div className='input_container'>
                    <label>
                        Repite la contraseña
                    </label>
                    <input type='password' value={repeatPassword} onChange={(e) => setRepeatPassword(e.target.value)} />
                </div>
                <div className='action'>
                    <button className='light_btn' onClick={GoToPrevius} disabled={loading}>
                        Cancelar
                    </button>
                    <button className='light_btn' disabled={loading} onClick={postUpdatePassword}>
                        Guardar
                    </button>
                </div>

                {
                    loading && (
                        <div className='loader'>
                            <BarLoader />
                        </div>
                    )
                }
            </div>
        </ScreenContainer>
    );
}