import { useEffect, useState } from "react";
import { AUTH_MODALS } from "../../pages/AuthPage/AuthPageContext";
import { AuthModal } from "../AuthModal/AuthModal"
import { BarLoader } from "../BarLoader/BarLoader";
import { useServer } from "../../contexts/ServerContext";
import { endpoints } from "../../env";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export const RegisterModal = () => {
    //HOOKS
    const { postRequest } = useServer();
    const { setAuthToken, checkAuth } = useAuth();
    const navigate = useNavigate();

    //STATES
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [username, setUsername] = useState("@");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [nameError, setNameError] = useState("");
    const [usernameError, setUsernameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");

    //FUNCTIONS
    const postRegister = () => {
        if (loading)
        {
            return;
        }

        setLoading(true);
        clearErrors();

        const data = {
            name,
            username : username.slice(1),
            email,
            password,
        }

        postRequest(endpoints.regiser, data, (res) => {
            setLoading(false);
            
            if (res[0])
            {
                res = res[1];
                if (res.success)
                {
                    setAuthToken(res.session.token);
                    checkAuth();
                    navigate("/app", {replace: true});
                }
                else
                {
                    if (res.error_code === "validator_exception")
                    {
                        if (res.errors.email) setEmailError("Se requiere el correo");
                        if (res.errors.name) setNameError("Se requiere el nombre");
                        if (res.errors.password) setPasswordError("Se requiere la contraseña");
                        if (res.errors.username) setUsernameError("Se requiere un nombre de usuario");
                    }

                    if (res.error_code === "used_email")
                    {
                        setEmailError("Ya existe una cuenta registrada con este correo");
                    }

                    if (res.error_code === "used_username")
                    {
                        setUsernameError("Ya existe una cuenta registrada con este nombre de usuario");
                    }
                }
            }
            else
            {
                setErrorMsg("Error del servidor, intenta de nuevo en un momento.");
            }
        });
    }

    const clearErrors = () => {
        setErrorMsg("");
        setEmailError("");
        setPasswordError("");
        setNameError("");
        setUsernameError("");
    }

    //EFFECTS
    useEffect(() => {
        if (username === "")
        {
            setUsername("@");
        }
    }, [username]);

    return (
        <AuthModal title="Crear cuenta" modal={AUTH_MODALS.REGISTER_MODAL}>
            <div className='input_container'>
                <label>
                    Nombre
                </label>
                <input type='text' placeholder='Nombre' value={name} onChange={(e) => setName(e.target.value)} />
                <span>
                    {nameError}
                </span>
            </div>
            <div className='input_container'>
                <label>
                    Usuario
                </label>
                <input type='text' placeholder='Usuario' value={username} onChange={(e) => setUsername(e.target.value)} />
                <span>
                    {usernameError}
                </span>
            </div>
            <div className='input_container'>
                <label>
                    Correo
                </label>
                <input type='text' placeholder='email@email.com' value={email} onChange={(e) => setEmail(e.target.value)} />
                <span>
                    {emailError}
                </span>
            </div>
            <div className='input_container'>
                <label>
                    Contraseña
                </label>
                <input type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                <span>
                    {passwordError}    
                </span> 
            </div>

            <div className='login_action'>
                <button className='light_btn' disabled={loading} onClick={postRegister}>
                    Crear cuenta
                </button>

                <span className='error'>
                    {errorMsg}
                </span>

                {
                    loading && (
                        <div className='loader'>
                            <BarLoader />
                        </div>
                    )
                }
            </div>
        </AuthModal>
    );
}